import { EmblaCarouselType } from "embla-carousel";
import GLightbox from "glightbox";
import { addUsercentricsConsentEventListener } from "./usercentrics";

const toggleArrowVisibility = () => {
  const sliderArrows = document.querySelectorAll<HTMLElement>(".slider-arrow");

  sliderArrows.forEach((el) => {
    el.classList.toggle("hide");
  });
};

const createImageLightbox = (name: string) => {
  GLightbox({
    selector: `.${name}`,
    touchNavigation: true,
    touchFollowAxis: true,
    loop: false,
    onOpen: toggleArrowVisibility,
    onClose: toggleArrowVisibility,
  });
};

const createVideoLightbox = () => {
  let isYouTubeVideoConsented = false;

  addUsercentricsConsentEventListener((event) => {
    if (!event.detail["YouTube Video"] || isYouTubeVideoConsented) return;

    createImageLightbox("glightbox-video");

    isYouTubeVideoConsented = true;
  });
};

export const initMediaLightboxes = () => {
  ["glightbox1", "glightbox2"].forEach((name) => createImageLightbox(name));

  createVideoLightbox();
};

const openLightbox = (
  e: MouseEvent,
  lightboxModule: HTMLElement,
  winnerCarousel: EmblaCarouselType
) => {
  const target = <HTMLElement>e.target;
  const winnerLightbox = target.closest<HTMLAnchorElement>(".winner .lightbox");

  if (!winnerLightbox) return;

  e.preventDefault();

  lightboxModule.classList.add("active");

  const id = new URL(winnerLightbox.href).hash.replace("#", "");

  winnerCarousel.scrollTo(+id, true);
};

const closeLightbox = (e: MouseEvent, lightboxModule: HTMLElement) => {
  if (!lightboxModule.classList.contains("active")) return;

  const target = <HTMLElement>e.target;
  const closeButton = target.closest(".close");

  const closeClickNotLightbox = target.closest(".lightbox-slider");

  if (!closeButton && closeClickNotLightbox) return;

  lightboxModule.classList.remove("active");
};

export const initWinnerLightbox = (winnerCarousel: EmblaCarouselType) => {
  const lightboxModule = document.querySelector<HTMLElement>(
    ".modular-lightbox-content"
  );

  if (!lightboxModule) return;

  document.addEventListener("click", (e) => {
    closeLightbox(e, lightboxModule);
    openLightbox(e, lightboxModule, winnerCarousel);
  });
};
