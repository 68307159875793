export const initMobileNavigation = () => {
  const navbarTogglerEl = document.querySelector("#navbar-toggler");
  const header = document.querySelector(".navigation");
  const navMenu = document.querySelector(".nav-menu");

  if (!navbarTogglerEl || !header || !navMenu) return;

  navbarTogglerEl.addEventListener("click", () => {
    header.classList.toggle("active");
    navbarTogglerEl.classList.toggle("active");
  });

  navMenu.addEventListener("click", (e) => {
    // Condition to open submenu without closing the whole msenu
    if (e.target instanceof HTMLAnchorElement) {
      header.classList.remove("active");
      navbarTogglerEl.classList.remove("active");
    }
  });
};
