export default () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const isDevEnvironment =
    location.hostname === "localhost" || /\.local$/i.test(location.hostname);

  let isSentryLoaded = false;

  const onFirstError = async (event: ErrorEvent) => {
    const Sentry = await import("@sentry/browser");

    !isSentryLoaded &&
      Sentry.init({
        dsn: "https://e32fc886197c4dd19783cc502f392629@o179954.ingest.sentry.io/6417310",
        enabled: !isDevEnvironment,
        autoSessionTracking: false,
      });

    isSentryLoaded = true;
    window.removeEventListener("error", onFirstError);

    Sentry.captureException(event.error);
  };

  window.addEventListener("error", onFirstError);
};
