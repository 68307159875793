let lastScrollTopOffset = 0;

const desktopHeader = (
  scrollContainer: HTMLElement,
  headerElement: HTMLElement
) => {
  const scrollTopOffset =
    scrollContainer.offsetTop || scrollContainer.scrollTop;
  const scrollOverWindowHeight = scrollTopOffset >= 1;
  fixHeader(scrollOverWindowHeight, headerElement);
  collapseHeader(scrollTopOffset, headerElement);

  lastScrollTopOffset = scrollTopOffset;
};

const fixHeader = (
  scrollOverWindowHeight: boolean,
  headerElement: HTMLElement
) => {
  const homePageCheck = headerElement.classList.contains("home-header");

  headerElement.classList.toggle(
    "header-fixed",
    !homePageCheck || scrollOverWindowHeight
  );
};

const collapseHeader = (
  scrollTopOffset: number,
  headerElement: HTMLElement
) => {
  scrollTopOffset > lastScrollTopOffset;
  headerElement.classList.toggle(
    "header-collapsed",
    scrollTopOffset > lastScrollTopOffset
  );
};

const handleHeader = (
  scrollContainer: HTMLElement,
  headerElement: HTMLElement
) => {
  const scrollContainerInner = scrollContainer.querySelector<HTMLElement>(
    ".scroll-container-inner"
  );

  const scrollbarWidth =
    scrollContainer.offsetWidth - (scrollContainerInner?.offsetWidth ?? 0);

  headerElement.style.right = scrollbarWidth + "px";

  if (window.innerWidth <= 992) {
    headerElement.classList.remove("header-fixed", "header-collapsed");
    return;
  }

  desktopHeader(scrollContainer, headerElement);
};

export const initScroll = () => {
  const scrollContainer =
    document.querySelector<HTMLElement>(".scroll-container");
  const headerElement = document.querySelector<HTMLElement>(".main-header");

  if (!scrollContainer || !headerElement) return;

  const events = [
    {
      target: window,
      event: "resize",
    },
    {
      target: window,
      event: "load",
    },
    {
      target: scrollContainer,
      event: "scroll",
    },
  ];

  for (let event of events) {
    event["target"]?.addEventListener(event["event"], () =>
      handleHeader(scrollContainer, headerElement)
    );
  }
};
