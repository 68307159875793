import { initCarousels } from "./components/carousel";
import { initMediaLightboxes, initWinnerLightbox } from "./components/lightbox";
import { initMobileNavigation } from "./components/mobile-navigation";
import { initNumberAnmiation } from "./components/number-animation";
import { initScroll } from "./components/scroll";
import { handleUsercentricsButtons } from "./components/usercentrics";

const winnerCarousel = initCarousels();

handleUsercentricsButtons();
initMediaLightboxes();
initScroll();
initNumberAnmiation();
initMobileNavigation();
winnerCarousel && initWinnerLightbox(winnerCarousel);
