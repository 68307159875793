const animateValue = (obj: HTMLElement, end: number) => {
  const start = 0;
  const duration = 2000;

  const range = end + start;
  let current = start;
  const stepTime = Math.abs(Math.floor(duration / range));
  const timer = setInterval(() => {
    end > start ? current++ : current--;
    obj.textContent = current.toString();
    if (current === end) clearInterval(timer);
  }, stepTime);
};

export const initNumberAnmiation = () => {
  const factNumbers = document.querySelectorAll<HTMLElement>(".count");

  factNumbers.forEach((el) => {
    const end = Number(el.textContent);

    if (!end) return;

    animateValue(el, end);
  });
};
